<template>
  <v-container fluid>
    <div class="container">
      <h1>Point quotidient</h1>




      <v-row>
        <v-col cols="12" md="3">
          <v-menu
            ref="startPeriodMenu"
            v-model="startPeriodMenu"
            :close-on-content-click="false"
            :return-value.sync="startPeriodModel"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startPeriodModel"
                label="Date début"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startPeriodModel" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="startPeriodMenu = false">
                Annulé
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.startPeriodMenu.save(startPeriodModel)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            ref="endPeriodMenu"
            v-model="endPeriodMenu"
            :close-on-content-click="false"
            :return-value.sync="endPeriodModel"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endPeriodModel"
                label="Date fin"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endPeriodModel" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="endPeriodMenu = false">
                Annulé
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.endPeriodMenu.save(endPeriodModel)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <div class="">
            <v-btn
              :loading="loading"
              @click="init"
              class="white--text green rounded-pill elevation-0"
              ><v-icon size="17" class="mr-2">mdi-magnify</v-icon> Appliquer la
              recherche</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" md="3" class="">
          <div class="d-flex justify-end">
            
          </div>
        </v-col>

        <v-col cols="12" md="3">
          <v-select
            class="pt-5 pa-2"
            label="Trie sur "
            prepend-icon="mdi-magnify"
            v-model="sortType"
            :items="[
              'Tout les frais',
              'Les frais annexes',
              // 'Les frais additionnels',
              'Entrée',
            ]"
          ></v-select>
        </v-col>
        <v-col
          v-if="['Tout les frais', 'Les frais annexes'].includes(sortType)"
          cols="12"
          md="3"
        >
          <v-select
            class="pt-5 pa-2"
            label="Trie sur les frais annexes "
            prepend-icon="mdi-magnify"
            v-model="searchStandardFeesName"
            :items="StandardFees.map((item) => item.name)"
            @change="init"
          ></v-select>
        </v-col>
        <v-col
          v-if="['Tout les frais', 'Les frais additionnels'].includes(sortType)"
          cols="12"
          md="3"
        >
          <v-select
            class="pt-5 pa-2"
            label="Trie sur les frais additionnels "
            prepend-icon="mdi-magnify"
            v-model="searchAdditionalCoastName"
            :items="AdditionalCoasts.map((item) => item.name)"
            @change="init"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            class="pt-5 pa-2"
            label="Trie sur les options "
            prepend-icon="mdi-magnify"
            v-model="searchOption"
            :items="Options.map((item) => item.name)"
            @change="init"
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <!-- standard payment -->
    <div v-if="['Tout les frais', 'Les frais annexes'].includes(sortType)">
      <v-data-table
        class="pt-3"
        flat
        :loading="loading ? '#144881' : null"
        :headers="standardPaymentHeaders"
        :search="searchData"
        :items="
          DailyStitch.standardPayments ? DailyStitch.standardPayments : []
        "
        :items-per-page="5"
        sort-by=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <v-icon left> mdi-human-male-female </v-icon>
              Paiement des frais standard de l'étudiant(e)
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <span class="mr-3">
              <a
              v-if="DailyStitch.entryResultStandardPaymentPdfExportLink"
              :href="DailyStitch.entryResultStandardPaymentPdfExportLink"
              target="_BLANK"
              class="rounded primary white--text px-3 py-2 font-weight-bold"
              ><v-icon color="white">mdi-printer</v-icon> Generer le pdf</a
            >

            </span>
            <span
              v-if="DailyStitch.totalStandardPaymentAmount"
              class="green text-subtitle-2 white--text rounded py-2 px-3"
            >
              {{ DailyStitch.totalStandardPaymentAmount }} FCFA</span
            >
            <template> </template>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu elevation="0" right offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="mr-md-10">
                <v-icon color="success" size="30">mdi-forwardburger</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-tooltip bottom color="green">
                <template v-slot:activator="{ on, attrs }">
                  <router-link :to="'/caisse/student/' + item._id">
                    <v-btn class="ml-3" icon>
                      <v-icon size="20" v-bind="attrs" v-on="on" color="green"
                        >mdi-arrow-right</v-icon
                      >
                    </v-btn>
                  </router-link>
                </template>
                <span>Déveloper</span>
              </v-tooltip>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.student`]="{ item }">
          <td>
            {{ item.standardDebt.student.lastName }}
            {{ item.standardDebt.student.firstName }}
          </td>
        </template>
        <template v-slot:[`item.registrationNumber`]="{ item }">
          <td>
            {{ item.standardDebt.student.registrationNumber }}
          </td>
        </template>
        <template v-slot:[`item.standardFees`]="{ item }">
          <td>
            {{ item.standardDebt.name }}
            {{ item.standardDebt.standardFees.name }}
          </td>
        </template>
      </v-data-table>
    </div>

    <!-- additional payment -->
    <div v-if="['Tout les frais', 'Les frais additionnels'].includes(sortType)">
      <v-data-table
        class="pt-3"
        flat
        :loading="loading ? '#144881' : null"
        :headers="additionalPaymentHeaders"
        :search="searchData"
        :items="
          DailyStitch.additionalPayments ? DailyStitch.additionalPayments : []
        "
        :items-per-page="5"
        sort-by=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <v-icon left> mdi-human-male-female </v-icon>
              Paiement des frais additionnel de l'étudiant(e)
            </v-toolbar-title>

            <v-spacer></v-spacer>
             <span class="mr-3">
              <a
              v-if="DailyStitch.entryResultAdditionalPaymentPdfExportLink"
              :href="DailyStitch.entryResultAdditionalPaymentPdfExportLink"
              target="_BLANK"
              class="rounded primary white--text px-3 py-2 font-weight-bold"
              ><v-icon color="white">mdi-printer</v-icon> Generer le pdf</a
            >

            </span>
            <span
              v-if="DailyStitch.totalAdditionalPaymentAmount"
              class="green text-subtitle-2 white--text rounded py-2 px-3"
            >
              {{ DailyStitch.totalAdditionalPaymentAmount }} FCFA</span
            >
            <template> </template>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu elevation="0" right offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="mr-md-10">
                <v-icon color="success" size="30">mdi-forwardburger</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-tooltip bottom color="green">
                <template v-slot:activator="{ on, attrs }">
                  <router-link :to="'/caisse/student/' + item._id">
                    <v-btn class="ml-3" icon>
                      <v-icon size="20" v-bind="attrs" v-on="on" color="green"
                        >mdi-arrow-right</v-icon
                      >
                    </v-btn>
                  </router-link>
                </template>
                <span>Déveloper</span>
              </v-tooltip>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.student`]="{ item }">
          <td>
            {{ item.additionalDebt.student.lastName }}
            {{ item.additionalDebt.student.firstName }}
          </td>
        </template>
        <template v-slot:[`item.registrationNumber`]="{ item }">
          <td>
            {{ item.standardDebt.student.registrationNumber }}
          </td>
        </template>

        <template v-slot:[`item.additionalCoast`]="{ item }">
          <td>
            {{ item.additionalDebt.name }}
            {{ item.additionalDebt.additionalCoast.name }}
          </td>
        </template>
      </v-data-table>
    </div>

    <!-- entrée -->
    <div v-if="['Entrée'].includes(sortType)">
      <div class="container">
        <div class="d-flex justify-end">
          <a
          v-if="DailyStitch.entryResultPdfExportLink"
          :href="DailyStitch.entryResultPdfExportLink"
          target="_BLANK"
          class="rounded green white--text px-3 py-2 font-weight-bold"
          ><v-icon color="white">mdi-printer</v-icon> Generer le pdf</a
        >
        </div>
      </div>
      <section>
        <v-data-table
          class="pt-3"
          flat
          :loading="loading ? '#144881' : null"
          :headers="entryHeaders"
          :search="searchData"
          :items="DailyStitch.entryResult ? DailyStitch.entryResult : []"
          sort-by=""
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                <v-icon left> mdi-human-male-female </v-icon>
                Paiement détaillé des entrées des étudiant(e)s
              </v-toolbar-title>

              <v-spacer></v-spacer>
              <span
                v-if="DailyStitch.totalAdditionalPaymentAmount"
                class="green text-subtitle-2 white--text rounded py-2 px-3"
              >
                {{ DailyStitch.totalAdditionalPaymentAmount }} FCFA</span
              >
              <template> </template>
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu elevation="0" right offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="mr-md-10">
                  <v-icon color="success" size="30">mdi-forwardburger</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-tooltip bottom color="green">
                  <template v-slot:activator="{ on, attrs }">
                    <router-link :to="'/caisse/student/' + item._id">
                      <v-btn class="ml-3" icon>
                        <v-icon size="20" v-bind="attrs" v-on="on" color="green"
                          >mdi-arrow-right</v-icon
                        >
                      </v-btn>
                    </router-link>
                  </template>
                  <span>Déveloper</span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:[`item.details`]="{ item }">
            <td class="">
              <ul>
                <li v-for="(detail, i) of item.details" :key="i">
                  <span class="font-weight-bold">{{ detail.fees }} : </span>
                  <span>{{ detail.payment }} </span>
                </li>
              </ul>
            </td>
          </template>
          <template v-slot:[`item.dateTime`]="{ item }">
            <td class="">
               <ul>
                <li v-for="(detail, i) of item.details" :key="i">
                  <span class="font-weight-bold">{{ detail.dateTime }}  </span>
                </li>
              </ul>
            </td>
          </template>
        </v-data-table>
      </section>
    </div>

    <v-dialog v-model="editorDialog" max-width="1000px" class="black">
      <editor-x
        :fields="fields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "@/utils/data";
import { addFaculty, updateFaculty, deleteFaculty } from "@/api/user";
import EditorX from "@/components/universal/EditorX.vue";
import DeleteDialog from "@/components/universal/DeleteDialog.vue";

export default {
  name: "DailyStitch",
  components: {
    EditorX,
    DeleteDialog,
  },
  data: () => ({
    sortType: "Tout les frais",
    callBack: "",
    searchData: "",
    dialog: false,
    loading: false,
    pdfLoading: false,
    startPeriodMenu: false,
    startPeriodModel: "",
    endPeriodMenu: false,
    endPeriodModel: "",
    searchStandardFeesName: "",
    searchAdditionalCoastName: "",
    searchOption: "",
    standardPaymentHeaders: [
      {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
        sortable: false,
      },
      {
        text: "Etudiant",
        align: "start",
        sortable: true,
        value: "student",
      },
      {
        text: "Numero matricule",
        align: "start",
        sortable: true,
        value: "registrationNumber",
      },
      {
        text: "Libellé",
        align: "start",
        sortable: true,
        value: "standardFees",
      },
      {
        text: "Montant payé",
        align: "start",
        sortable: true,
        value: "amount",
      },
      {
        text: "Date & Heure",
        align: "start",
        sortable: true,
        value: "createdAtTime",
      },
    ],
    entryHeaders: [
      // {
      //   text: "Actions",
      //   width: "150px",
      //   align: "left",
      //   value: "actions",
      //   sortable: false,
      // },

      {
        text: "Numero matricule",
        align: "start",
        sortable: true,
        value: "registrationNumber",
      },
      {
        text: "Nom et prénoms ",
        align: "start",
        sortable: true,
        value: "fullName",
      },
      {
        text: "Option",
        align: "start",
        sortable: true,
        value: "option",
      },
      {
        text: "Montant à payer",
        align: "start",
        sortable: true,
        value: "totalDebt",
      },
      {
        text: "Reduction",
        align: "start",
        sortable: true,
        value: "totalReduction",
      },
      {
        text: "Entrée",
        align: "start",
        sortable: true,
        value: "totalAmount",
      },
      {
        text: "Libellé",
        align: "start",
        sortable: true,
        value: "details",
      },
      {
        text: "Heure",
        align: "start",
        sortable: true,
        value: "dateTime",
      },
      {
        text: "Reste",
        align: "start",
        sortable: true,
        value: "totalLeft",
      },

      {
        text: "En caisse",
        align: "start",
        sortable: true,
        value: "totalInCash",
      },
      // {
      //   text: "Date & Heure",
      //   align: "start",
      //   sortable: true,
      //   value: "createdAtTime",
      // },
    ],
    additionalPaymentHeaders: [
      {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
        sortable: false,
      },
      {
        text: "Etudiant",
        align: "start",
        sortable: true,
        value: "student",
      },
      {
        text: "Numero matricule",
        align: "start",
        sortable: true,
        value: "registrationNumber",
      },
      {
        text: "Libellé",
        align: "start",
        sortable: true,
        value: "additionalCoast",
      },
      {
        text: "Montant payé",
        align: "start",
        sortable: true,
        value: "amount",
      },
      {
        text: "Date & Heure",
        align: "start",
        sortable: true,
        value: "createdAtTime",
      },
    ],
    editedIndex: -1,
    Model: {},
    defaultItem: {},
    editorDialog: false,
    deleteDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters([
      "DailyStitch",
      "Schools",
      "UserProfile",
      "AdditionalCoasts",
      "StandardFees",
      "Options",
    ]),
    fields() {
      return [
        {
          name: "name",
          label: "Nom",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "acronym",
          label: "Acronyme",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "description",
          label: "Descripiton",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "school",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value:
            this.UserProfile.school && this.UserProfile.school._id
              ? this.UserProfile.school._id
              : this.UserProfile.school,
        },
      ];
    },
  },

  watch: {},
  methods: {
    ...mapActions([
      "getDailyStitch",
      "getAdditionalAndStandardFees",
      "getOptions",
    ]),
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
      this.pdfLoading = true;
      if (event == 100) {
        this.pdfLoading = false;
      }
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getDailyStitch({
          periodStartDate: this.startPeriodModel,
          periodEndDate: this.endPeriodModel,
          standardFeesName: this.searchStandardFeesName,
          standardCoastName: this.searchAdditionalCoastName,
          option: this.searchOption,
        });

        await this.getAdditionalAndStandardFees({ withoutDepartment: false });
        await this.getOptions({ department: "" });
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les années")
        );
      }
      this.loading = false;
    },
    successEditor() {
      this.editorDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    addItem() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addFaculty;
      this.editorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.editorDialog = true;
      this.callBack = updateFaculty;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteFaculty;
    },
  },
};
</script>

<style >
td {
  font-size: 0.7rem;
}
.font-weight-bold {
  font-weight: bold !important;
}
</style>
